$(document).ready(function() {
  /* scroll to ankor */

    $('a.scrollto').on('click', function() {
      elementClick = $(this).attr("href");
      destination = $(elementClick).offset().top-5;
      $("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 700);
      return false;
    });

  /* back to top */
    $(window).scroll(function(){
        if ($(this).scrollTop() > 50) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });
    
    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });

    // open description section-5
    $('.view-description').on('click', function() {
        //$(this).closest('.list-course-item').toggleClass('open');
        const txt = $(this).text();
        const par = $(this).closest('.list-course-item');

        par.toggleClass('open');
        

        if(txt == 'Подробнее'){
          $(this).text('Скрыть описание');
          par.find('.list-course-item-description').animate({
            opacity: 1,
          }, 500);
        }
        else{
          $(this).text('Подробнее');
          par.find('.list-course-item-description').animate({
            opacity: 0,
          }, 10);
        }
    });

    // view more lectors
    var items = $('.lector-list-item'),
    per = 1,
    i = 1,
    total = 0;
    $('.lector-more-btn').on('click', function(event){
        event.preventDefault();
        total = per * (i++);
          items.slice(0, total).show();
            $(this)[total >= items.length ? 'hide' : 'show']();
    }).click();
});